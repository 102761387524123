import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import CallToActionSection from "../../components/CallToActionSection";
import Footer from "../../components/Footer";
import HeroSection from "../../components/HeroSection";
import PricingSection from "../../components/PricingSection";
import ProgramsSection from "../../components/ProgramsSection";
import StatsSection from "../../components/StatsSection";
import TestimonialsSection from "../../components/TestimonialsSection";
import TopBar from "../../components/TopBar";
import UniqueSellingPoints from "../../components/UniqueSellingPoints";

const LandingPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // Use a slight delay to ensure the section has rendered
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    }
  }, [location]);

  return (
    <>
      <TopBar />
      <HeroSection />
      <ProgramsSection />
      <StatsSection />
      <UniqueSellingPoints />
      <PricingSection />
      <TestimonialsSection />
      <CallToActionSection />
      <Footer />
    </>
  );
};

export default LandingPage;
