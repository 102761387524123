import React from "react";

const PrivacyTopbar: React.FC = () => {
  return (
    <nav
      id="PrivacyTopbar"
      className="px-36 h-20 flex items-center justify-between text-sm"
    >
      <div className="flex justify-start items-center">
        <a href="#topbar" className="flex items-center">
          <img className="w-8 h-8" src="/favicon.ico" alt="Logo" />
          <div className="text-xl ml-1 font-extrabold">STEM Essay!</div>
        </a>
      </div>
      <div className="flex items-center">
        <a href="/login" className="text-gray-500 hover:text-gray-900 px-4">
          Log In
        </a>
        <a
          href="https://airtable.com/appfrX6dbb3c6baEz/shrIGfNTAY2PRcEFv"
          className="bg-blue-500 h-10 px-4 hover:bg-blue-700 text-white rounded-md flex items-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          Start for Free
        </a>
      </div>
    </nav>
  );
};

export default PrivacyTopbar;
