import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import BlogPage from "../screens/BlogPage";
import GalleryPage from "../screens/GalleryPage";
import LandingPage from "../screens/LandingPage/LandingPage";
import NotFoundPage from "../screens/NotFoundPage";
import PrivacyPolicy from "../screens/PrivacyPolicy";

function AppRoutes() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </HashRouter>
  );
}

export default AppRoutes;
