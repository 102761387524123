import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const TopBar: React.FC = () => {
  return (
    <nav
      id="topbar"
      className="px-36 h-20 flex items-center justify-between text-sm"
    >
      <div className="flex items-center">
        <Link to="/" className="flex items-center">
          <img className="w-8 h-8" src="/favicon.ico" alt="Logo" />
          <div className="text-xl ml-1 font-extrabold">STEM Essay!</div>
        </Link>
      </div>
      <div className="flex-1 flex justify-center space-x-8">
        <Link
          to="/blog"
          rel="noopener noreferrer"
          className="hover:text-gray-900"
        >
          Blog
        </Link>
        <Link
          to="/gallery"
          rel="noopener noreferrer"
          className="hover:text-gray-900"
        >
          Gallery
        </Link>
        <Link to="/#pricing" smooth className="hover:text-gray-900">
          Pricing
        </Link>
        <Link to="/#testimonial" smooth className="hover:text-gray-900">
          Testimonial
        </Link>
      </div>
      <div className="flex items-center">
        <a
          href="http://54.224.133.73/login"
          className="text-gray-500 hover:text-gray-900 px-4"
        >
          Log In
        </a>
        <a
          href="http://54.224.133.73/login?redirect=/signup"
          className="bg-blue-500 h-10 px-4 hover:bg-blue-700 text-white rounded-md flex items-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          Start for Free
        </a>
      </div>
    </nav>
  );
};

export default TopBar;
