import React from "react";
import { HashLink as Link } from "react-router-hash-link";

const Footer: React.FC = () => {
  return (
    <footer
      id="footer"
      style={{ height: "80px" }} // Set the height here
      className="bg-gray-900 text-xs text-gray-400 py-4 mx-auto px-36 flex justify-between"
    >
      <div className="flex gap-4 mt-4">
        <Link to="/#pricing" smooth className="hover:text-gray-300">
          Pricing
        </Link>
        |
        <Link to="/#testimonial" smooth className="hover:text-gray-300">
          Testimonial
        </Link>
      </div>
      <div className="flex gap-4 mt-4">
        <Link
          to="/policy"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          Privacy Policy
        </Link>
        |<span>© 2023 STEM Essays</span>
      </div>
    </footer>
  );
};

export default Footer;
