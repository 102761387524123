import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import { ReactComponent as DiscordIcon } from "../assets/discord-white.svg"; // Make sure you have the Discord icon SVG in your assets folder
import { ReactComponent as FacebookIcon } from "../assets/facebook-white.svg";
import { ReactComponent as InsIcon } from "../assets/ins-white.svg";
import { ReactComponent as TtIcon } from "../assets/tt-white.svg";
import { ReactComponent as XIcon } from "../assets/x-white.svg";
import { ReactComponent as YoutuberIcon } from "../assets/youtuber-white.svg";

const FooterBlog: React.FC = () => {
  return (
    <footer
      id="footer"
      className="bg-gray-900 text-xs text-gray-400 mt-24 py-4 px-4 sm:px-8 md:px-12 lg:px-36 flex flex-col sm:flex-row justify-between items-center"
      style={{ height: "80px" }} // Set the height here
    >
      <div className="flex gap-4 mb-4 sm:mb-0">
        <Link to="/#pricing" smooth className="hover:text-gray-300">
          Pricing
        </Link>
        |
        <Link to="/#testimonial" smooth className="hover:text-gray-300">
          Testimonial
        </Link>
      </div>

      <div className="flex gap-4 sm:gap-6 mb-4 sm:mb-0 justify-center">
        <a
          href="https://twitter.com/EssayStem93096"
          target="_blank"
          rel="noopener noreferrer"
        >
          <XIcon className="h-6" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61555698471344"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon className="h-6" />
        </a>
        <a
          href="https://www.tiktok.com/@stem.essay?_t=8mM40bJGwsU&_r=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TtIcon className="h-6" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCCwiLsz2dQAkpZtWvZTs_Tg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutuberIcon className="h-6" />
        </a>
        <a
          href="https://www.instagram.com/kakooib/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InsIcon className="h-6" />
        </a>
        <a
          href="https://discord.gg/jrazJqtV"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DiscordIcon className="h-6" />
        </a>
      </div>

      <div className="flex gap-4">
        <Link
          to="/policy"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          Privacy Policy
        </Link>
        |<span>© 2023 STEM Essays</span>
      </div>
    </footer>
  );
};

export default FooterBlog;
