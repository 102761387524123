import React, { useEffect } from "react";

const GallerySection: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.rss.app/v1/list.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="container mx-auto py-12 px-4 sm:px-8 md:px-12 lg:px-36 mt-12">
      <div className="text-center mb-24">
        <h1 className="text-3xl sm:text-4xl font-bold mb-12">
          STEM Essay Gallery
        </h1>
        <p className="text-lg sm:text-xl text-gray-600">
          Explore our collection of educational and technology insights through
          visuals.
        </p>
      </div>
      <div className="rss-feed-container mt-12">
        <rssapp-list id="aFlQJdYg9RAhv09T"></rssapp-list>
      </div>
    </div>
  );
};

export default GallerySection;
