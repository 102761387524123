import React from "react";

import BlogSection from "../components/Blog";
import Footer from "../components/FooterBlog";
import TopbarSection from "../components/TopBar";
//import ShowcaseSection from "../components/BlogShowcase";

const BlogPage: React.FC = () => {
  return (
    <div id="root">
      <TopbarSection />
      <BlogSection />
      <Footer />
    </div>
  );
};

export default BlogPage;
