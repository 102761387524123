import React from "react";

import Footer from "../components/Footer";
import PrivacySection from "../components/PrivacyPolicy";
import PrivacyTopbarSection from "../components/PrivacyTopbar";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <PrivacyTopbarSection />
      <PrivacySection />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
