import React from "react";

import Footer from "../components/FooterBlog";
import GallerySection from "../components/GallerySeciton";
import TopbarSection from "../components/TopBar";

const BlogPage: React.FC = () => {
  return (
    <div id="root">
      <TopbarSection />
      <GallerySection />
      <Footer />
    </div>
  );
};

export default BlogPage;
