import React from "react";

import { ReactComponent as FacebookIcon } from "../assets/facebook.svg";
import { ReactComponent as InsIcon } from "../assets/ins.svg";
import { ReactComponent as TtIcon } from "../assets/tiktok.svg";
import { ReactComponent as XIcon } from "../assets/x black.svg";
import { ReactComponent as YoutuberIcon } from "../assets/youtuber black.svg";

const CallToActionSection: React.FC = () => {
  return (
    <div
      className="bg-yellow-300 px-36 py-28 mt-36 flex flex-col items-center justify-between"
      style={{ maxHeight: "350px" }}
    >
      <div className="text-4xl font-bold text-center">
        Compose your initial essay draft today.
      </div>
      <a
        href="https://airtable.com/appfrX6dbb3c6baEz/shrIGfNTAY2PRcEFv"
        className="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 mt-4 rounded flex items-center"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>Start for Free</span>
      </a>
      <div className="w-full flex justify-center gap-6 pb-1 mt-28">
        {" "}
        {/* Adjusted to place icons at the bottom */}
        <a
          href="https://twitter.com/EssayStem93096"
          target="_blank"
          rel="noopener noreferrer"
        >
          <XIcon style={{ height: "25px" }} />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61555698471344"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon style={{ height: "25px" }} />
        </a>
        <a
          href="https://www.tiktok.com/@stem.essay?_t=8mM40bJGwsU&_r=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TtIcon style={{ height: "25px" }} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCCwiLsz2dQAkpZtWvZTs_Tg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutuberIcon style={{ height: "25px" }} />
        </a>
        <a
          href="https://www.instagram.com/kakooib/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InsIcon style={{ height: "25px" }} />
        </a>
      </div>
    </div>
  );
};

export default CallToActionSection;
