import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="flex flex-col items-center py-12 px-4 md:px-12 lg:px-24 mb-12">
      <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
      <div className="max-w-3xl text-left text-gray-700">
        <p>
          Welcome to STEM Essay. We are committed to protecting your privacy and
          handling your data in an open and transparent manner. This Privacy
          Policy describes our practices with respect to Personal Information we
          collect from or about you when you use our website, applications, and
          services.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          1. Personal information we collect
        </h2>
        <p>
          We collect personal information relating to you (“Personal
          Information”) as follows:
        </p>
        <ul className="list-disc list-inside text-gray-500 mt-4">
          <li>
            Account Information: When you create an account with us, we will
            collect information associated with your account, including your
            name, contact information, account credentials, payment card
            information, and transaction history.
          </li>
          <li>
            User Content: When you use our Services, we collect Personal
            Information that is included in the input, file uploads, or feedback
            that you provide to our Services.
          </li>
          <li>
            Communication Information: If you communicate with us, we collect
            your name, contact information, and the contents of any messages you
            send.
          </li>
          <li>
            Log Data: Information that your browser or device automatically
            sends when you use our Services. Log data includes your Internet
            Protocol address, browser type and settings, the date and time of
            your request, and how you interact with our Services.
          </li>
          <li>
            Usage Data: We may automatically collect information about your use
            of the Services, such as the types of content that you view or
            engage with, the features you use and the actions you take, as well
            as your time zone, country, the dates and times of access, user
            agent and version, type of computer or mobile device, and your
            computer connection.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          2. How we use personal information
        </h2>
        <p>We may use Personal Information for the following purposes:</p>
        <ul className="list-disc list-inside text-gray-500 mt-4">
          <li>To provide, administer, maintain and/or analyze the Services;</li>
          <li>To improve our Services and conduct research;</li>
          <li>
            To communicate with you; including to send you information about our
            Services and events;
          </li>
          <li>To develop new programs and services;</li>
          <li>
            To comply with legal obligations and legal process and to protect
            our rights, privacy, safety, or property, and/or that of our
            affiliates, you, or other third parties.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          3. Disclosure of personal information
        </h2>
        <p>
          In certain circumstances, we may provide your Personal Information to
          third parties without further notice to you, unless required by the
          law:
        </p>
        <ul className="list-disc list-inside text-gray-500 mt-4">
          <li>
            Vendors and Service Providers: To assist us in meeting business
            operations needs and to perform certain services and functions, we
            may provide Personal Information to vendors and service providers,
            including providers of hosting services, customer service vendors,
            cloud services, email communication software, web analytics
            services, and other information technology providers, among others.
            Pursuant to our instructions, these parties will access, process, or
            store Personal Information only in the course of performing their
            duties to us.
          </li>
          <li>
            Business Transfers: If we are involved in strategic transactions,
            reorganization, bankruptcy, receivership, or transition of service
            to another provider, your Personal Information and other information
            may be disclosed in the diligence process with counterparties and
            others assisting with the Transaction and transferred to a successor
            or affiliate as part of that Transaction along with other assets.
          </li>
          <li>
            Legal Requirements: We may share your Personal Information,
            including information about your interaction with our Services, with
            government authorities, industry peers, or other third parties (i)
            if required to do so by law or in the good faith belief that such
            action is necessary to comply with a legal obligation, (ii) to
            protect and defend our rights or property, (iii) if we determine, in
            our sole discretion, that there is a violation of our terms,
            policies, or the law; (iv) to detect or prevent fraud or other
            illegal activity; (v) to protect the safety, security, and integrity
            of our products, employees, or users, or the public, or (vi) to
            protect against legal liability.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Data Security</h2>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal data when you enter, submit, or access your personal
          data to prevent unauthorized access, use, or disclosure.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          5. Your Data Protection Rights
        </h2>
        <p>
          Depending on location, individuals may have certain statutory rights
          in relation to their Personal Information. For example, you may have
          the right to:
        </p>
        <ul className="list-disc list-inside text-gray-500 mt-4">
          <li>
            The right to access, update or delete the information we have on
            you.
          </li>
          <li>
            The right of rectification if that information is inaccurate or
            incomplete.
          </li>
          <li>The right to object to our processing of your personal data.</li>
          <li>
            The right to request that we restrict the processing of your
            personal data.
          </li>
          <li>
            The right to withdraw consent at any time where we rely on your
            consent to process your personal data.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          6. Changes to This Privacy Policy
        </h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">7. Contact Us</h2>
        <p>
          If you have any questions, comments, or concerns about this Policy or
          our privacy practices, please contact us at contact@stemessay.com.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
